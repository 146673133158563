import { getBalanceInEther } from 'utils/formatBalance';
import { getStakes, getMasterChefAbi, getMasterChefAddress, getRewardEmissionRateFunctionName } from 'utils/stake';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import ERC20 from 'config/abi/Erc20.json';
import multicall from 'utils/multicall';
import { StakeCategory } from 'types/stake';
import DeployerAbi from "config/abi/Deployer.json"
import { getDeployAddress } from 'utils/deployTokens';

// fetch reward pool farms (smelt reward pools and dead pools)
export const fetchDeployedTokens = async (account: string): Promise<any> => {
  try {
    // get farm onchain inf
        const [deployedCount] = await multicall(
          DeployerAbi,
          [
            {
              address: getDeployAddress(),
              name: 'deployedCount',
              params: [],
            },
          ]
        );
        const indexes = [];

        for( let i = 5; i < deployedCount; i+=1 ){
            indexes.push(i);
        }

        const tokensDeployed = await Promise.all(
            indexes.map(async (row: any) => { 
                const [deployedTokenAddress] = await multicall(
                    DeployerAbi,
                    [
                      {
                        address: getDeployAddress(),
                        name: 'deployedTokens',
                        params: [row],
                      },
                    ]
                );
                const [info] = await multicall(
                    DeployerAbi,
                    [
                      {
                        address: getDeployAddress(),
                        name: 'tokensInfo',
                        params: [deployedTokenAddress[0]],
                      },
                    ]
                );

                const [symbol] = await multicall(
                    ERC20,
                    [
                      {
                        address: deployedTokenAddress[0],
                        name: 'symbol',
                        params: [],
                      },
                      
                    ]
                );
                let isUserToken = false;
                if( account ){
                    if( info.deployerAddress === account ){
                        isUserToken = true
                    }
                }
                let pool = {}
                if ( info.dfundTokenPool ){
                   const tokenAddress = deployedTokenAddress[0]
                   const [poolInfo, totalAllocPoint, totalDfundStaked, rewardPerSecond, poolStartTime] = await multicall(
                    getMasterChefAbi(),
                    [
                      {
                        address: info.dfundTokenPool,
                        name: 'poolInfo',
                        params: [0],
                      },
                      {
                        address: info.dfundTokenPool,
                        name: 'totalAllocPoint',
                      },
                      {
                        address: info.dfundTokenPool,
                        name: 'totalDfundStaked',
                      },
                      {
                        address: info.dfundTokenPool,
                        name: 'rewardPerSecond',
                      },
                      {
                        address: info.dfundTokenPool,
                        name: 'poolStartTime',
                      },
                    
                    ]
                  );
                  
                  const [balance] = await multicall(
                    ERC20,
                    [
                      {
                        address: deployedTokenAddress[0],
                        name: 'balanceOf',
                        params: [info.dfundTokenPool],
                      },
                      
                    ]
                   );
                                     
                  const [balanceStaked] = await multicall(
                    ERC20,
                    [
                      {
                        address: "0x0B946D939bb93609Fcce42220180E5C81B642786",
                        name: 'balanceOf',
                        params: [info.dfundTokenPool],
                      },
                      
                    ]
                   );

                    let poolRewards = getBalanceInEther(balance[0]);
                    const lockTime =  poolInfo.lockTime;

                    const allocPoint = Number(poolInfo.allocPoint);
                    const totalAllocPointRaw = getBalanceInEther(totalAllocPoint[0]);
                    const poolWeight = allocPoint / totalAllocPointRaw;
                    const aprYear = poolRewards * allocPoint / 100 / getBalanceInEther(balanceStaked[0]) * 100;
                    const rew = poolRewards/365/ (getBalanceInEther(balanceStaked[0]))
                    // console.log(poolInfo)
                    pool = {aprYear, poolInfo, lockTime, poolRewards, tvl: getBalanceInEther(totalDfundStaked[0]),
                       name: info.name, masterChefAddress: info.dfundTokenPool, stakingToken: "0x0B946D939bb93609Fcce42220180E5C81B642786", rewardPerSecond: Number(rew), poolStartTime}
                }
                return {info, symbol: symbol[0], isUserToken, logo: "", pool: pool};
            } 
            ) 
        )
        return tokensDeployed;
  } catch (error: any) {
    return [];
  }
};

export const fetchDeployedTokensGlobalInfo = async (account: string): Promise<any> => {
  const smeltRewardPoolFarmsData = await fetchDeployedTokens(account);

  return {
    data: [...smeltRewardPoolFarmsData],
  };
};

import { presaleInfos } from 'config/constants/presale';
import DeployAbi from 'config/abi/Deployer.json';
import AtlanticTokenAbi from 'config/abi/AtlanticToken.json';

export const getDeployAddress = () => {
    return "0x33caF4ce1bBE6C1BD47DF1012091ff888942448D";
};


export const getDeployTokenABI = () => {
    return DeployAbi;
};

import { icons } from 'config/constants/assets';

export interface RouteType {
  key: string;
  text: string;
  url: string;
}

export const routes: { [key: number]: RouteType[] } = {
  666666666: [
    { key: 'tokenomics', text: 'Tokenomics', url: 'tokenomics' },
    { key: 'goal', text: 'RoadMap', url: 'goal' },
    { key: 'howto', text: 'HowTo', url: 'howto' },
  ],
};

export const extraMenuItems: { [key: number]: any[] } = {
  666666666: [
    {
      name: 'Main menu',
      icon: "",
      key: 'main menu',
      hasSubItems: true,
      subItems: [
        { name: 'Stake DFund', key: 'stake', link: '/stake', color: '#CCC292', enabled: true },
        { name: 'Stake DFund-Degen LP', key: 'stakelp', link: '/stakelp', color: '#CCC292', enabled: true },
      ],
    },
  ],
};

import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { AbiItem } from 'web3-utils';
import { BigNumber, Contract, constants, ethers, providers } from "ethers";
import { useDispatch } from 'react-redux';
import { fetchDeployedTokensDataAsync, fetchPresaleUserInfoAsync } from "state/actions";
import pairAbi from 'config/abi/LpPair.json';
import ERC20 from 'config/abi/Erc20.json';
import multicall, { multicallNative} from 'utils/multicall';
import { getWeb3, getProviderBASE, getWeb3NoAccountBASE, getWeb3NoAccount } from 'utils/web3';

import { FarmInfo } from "types/stake";
import { getMasterChefAbi } from "utils/stake";
import {useContract, useToken} from "./useContract";
import { setPendingTxHash } from 'state/modal/modalSlice';

import { useNotification } from "./useNotification";
import { getBalanceInEther, getContract } from "utils";
import { getTokenPriceFromCGC } from "utils/coingecko";
import { getTokenPriceFromDexTools } from "utils/dextools";
import { Interface } from "ethers/lib/utils";

export const useStakeDeployedAccountInfo = (chainId: number, pool: any, counter: number) => {
  const { account } = useWeb3React();
  const [stat, setStat] = useState<any>();
  const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
  const token = useContract(ERC20 as unknown as AbiItem, pool ? pool?.stakingToken : '');

  useEffect(() => {
      async function getStakeInfo() {
        if( !contract || !token || !pool || !account ) return;
        let accountBalance = BigNumber.from(0);
        let userStaked = BigNumber.from(0);
        let stakeTime = BigNumber.from(0);
        let allowance = BigNumber.from(0);
        let pendingRewards = BigNumber.from(0);
        try {
          if( contract && token && pool){
              if( account ){
                  accountBalance = await token.balanceOf(account);
                  const userInfo = await contract.userInfo(0, account);
                  userStaked = userInfo.amount;
                  stakeTime = userInfo.stakeTime;
                  allowance = await token.allowance(account, contract.address)
                  pendingRewards= await contract.pendingReward(0, account);
              }
              setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: pool.masterChefAddress, stakingToken: pool.stakingToken, poolId: 0, isLp: false});
          }
        } catch (err) {
          console.log(err)
          // setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: "", stakingToken: "", poolId: 0, isLp: false});
        }
      }
   
      getStakeInfo().then();
    }, [ chainId, pool, contract, token, account, counter]);
    return stat;
}

export const useStakeEstimateInfo = (chainId: number, pool: FarmInfo, counter: number) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
    const token = useContract(ERC20 as unknown as AbiItem, pool ? pool?.stakingToken : '');
    const tokenLp = useContract(pairAbi as unknown as AbiItem, pool ? pool?.stakingToken0 : '');

    useEffect(() => {
        // if( !contract || !pool || !token ){
        //   setStat({value: 0});
        //   return;
        // }

        // TOTAL STAKED
        // TIME TILL 
        // APR 
        // DAILY APR
        // TIME UNTIL
        // REWARDS
        //TVL
        async function getStakeInfo() {
          try {
            // console.log("HERE222")
            if( contract && token && pool){
                const poolInfo = await contract.poolInfo(pool.poolId);
                const totalAllocPoint = await contract.totalAllocPoint();
                const allocPoint = Number(poolInfo.allocPoint);
                const totalAllocPointRaw = getBalanceInEther(totalAllocPoint);
                const poolWeight = allocPoint / totalAllocPointRaw;

                let totalStaked = BigNumber.from(0)
                if( pool.isLp && tokenLp ){
                  totalStaked = await tokenLp.balanceOf(pool.masterChefAddress)
                }
                else{
                  totalStaked = await contract.totalDfundStaked()
                }
                const stakingTokenSupply = await token.totalSupply();
                const rewardPerSecond = await contract.rewardPerSecond();
            
                const balance = await token.balanceOf(contract.address);

                let poolRewards = 0;
                poolRewards = (getBalanceInEther(balance) - getBalanceInEther(totalStaked) )/ 2;
                const aprYear = poolRewards * allocPoint / 100 / getBalanceInEther(totalStaked) * 100;
                // console.log(`Apr year: ${aprYear}`)
                let accountBalance = BigNumber.from(0);
                let userStaked = BigNumber.from(0);
                let stakeTime = BigNumber.from(0);
                let allowance = BigNumber.from(0);
                let pendingRewards = BigNumber.from(0);
                const  lockTime =  poolInfo.lockTime;
                let tvl = 0;
                if( pool.isLp && tokenLp){
                   const totalSupply =  await tokenLp.totalSupply();
                   const tokenTotalAmount = await token.balanceOf(tokenLp.address);
                   const tokenSupply = getBalanceInEther(tokenTotalAmount);
                   const totalSupplyInLp = getBalanceInEther(totalSupply)
                   const tokenInLP = tokenSupply/ Number(totalSupplyInLp);
                  //  console.log(`Price one lp = ${tokenInLP}`)
                   tvl = tokenInLP

                  //  const reserves =  await tokenLp.getReserves();
                  //  tvl = reserves[0] / reserves[1] * 2;

                   tvl *= getBalanceInEther(totalStaked)
                }
                else {
                  tvl = getBalanceInEther(totalStaked);
                }

                if( account && tokenLp ){
                    if( pool.isLp ){
                      accountBalance = await tokenLp.balanceOf(account);
                    }
                    else {
                      accountBalance = await token.balanceOf(account);
                    }
                    const userInfo = await contract.userInfo(pool.poolId, account);
                    userStaked = userInfo.amount;
                    stakeTime = userInfo.stakeTime;
                    if( pool.isLp && tokenLp ){
                      allowance = await tokenLp.allowance(account, contract.address)
                    } else {
                      allowance = await token.allowance(account, contract.address)
                    }
                    pendingRewards= await contract.pendingReward(pool.poolId, account);
                }
                setStat({poolInfo, totalAllocPoint, poolWeight, totalStaked, stakingTokenSupply, rewardPerSecond, aprYear, accountBalance, userStaked, stakeTime, allowance, pendingRewards, lockTime ,tvl});
            }
            else if(pool && !contract && !token) {
                throw("err")
            }

            // const res = await contract?.estimatePreSale(ethers.utils.parseUnits(amount.toString(), 18));
            // setStat({value: res});
          } catch (err) {
            // console.log(err);
            const web3 = getWeb3NoAccount();
            const cc = new web3.eth.Contract(getMasterChefAbi() as unknown as AbiItem, pool.masterChefAddress);
            const tt = new web3.eth.Contract(ERC20 as unknown as AbiItem, pool.stakingToken);
            const lpToken = new web3.eth.Contract(pairAbi as unknown as AbiItem, pool.stakingToken0);

            // add private key to wallet to have auto-signing transactions feature
            const poolInfo = await cc.methods.poolInfo(pool.poolId).call()
            const totalAllocPoint = await cc.methods.totalAllocPoint().call();

            const allocPoint = Number(poolInfo.allocPoint);
            const totalAllocPointRaw = getBalanceInEther(totalAllocPoint);
            const poolWeight = allocPoint / totalAllocPointRaw;
            let totalStaked = BigNumber.from(0)

            if( pool.isLp && lpToken ){
              totalStaked = await lpToken.methods.balanceOf(pool.masterChefAddress).call()
            }
            else{
              totalStaked = await cc.methods.totalDfundStaked().call()
            }

            let tvl = 0;
            if( pool.isLp && lpToken){
              //  const reserves =  await lpToken.methods.getReserves().call();
              //  tvl = reserves[0] / reserves[1] * 2;
              //  console.log(`Price one lp = ${tvl}`)

               const totalSupply =  await lpToken.methods.totalSupply().call();
               const tokenTotalAmount = await tt.methods.balanceOf(pool.stakingToken0).call();
               const tokenSupply = getBalanceInEther(tokenTotalAmount);
               const totalSupplyInLp = getBalanceInEther(totalSupply)
               const tokenInLP = tokenSupply/ Number(totalSupplyInLp);
               tvl = tokenInLP
               tvl *= getBalanceInEther(totalStaked)
            }
            else {
              tvl = getBalanceInEther(totalStaked);
            }

            const stakingTokenSupply = await tt.methods.totalSupply().call();
            const rewardPerSecond = await cc.methods.rewardPerSecond().call;
            // // Тотал реворд * аллокейшн / 100 / тотал стэйкд * 100
            // // Total
            // // console.log("Pre end")
            const balance = await tt.methods.balanceOf(pool.masterChefAddress).call();
            const poolRewards = (getBalanceInEther(balance) - getBalanceInEther(totalStaked)) / 2;
            // // console.log(poolInfo.allocPoint)
            // // console.log("END")
            // // console.log(`poolRewards: ${poolRewards}`)
            // // console.log(`allocPoint: ${allocPoint}`)
            // // console.log(`totalStaked: ${getBalanceInEther(totalStaked)}`)
            const aprYear = poolRewards * allocPoint / 100 / getBalanceInEther(totalStaked) * 100; //*2
            // // console.log(`Apr year: ${aprYear}`)
            let accountBalance = BigNumber.from(0);
            let userStaked = BigNumber.from(0);
            let stakeTime = BigNumber.from(0);
            let allowance = BigNumber.from(0);
            let pendingRewards = BigNumber.from(0);
            const  lockTime =  poolInfo.lockTime;
            setStat({poolInfo, totalAllocPoint, poolWeight, totalStaked, stakingTokenSupply,
               rewardPerSecond, aprYear, accountBalance, userStaked, stakeTime, allowance, pendingRewards, lockTime, tvl});
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, pool, contract, token, account, counter]);
      return stat;
}

export const useTokenApprove = (chainId: number, amount: string, pool: FarmInfo ) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
    
    useEffect(() => {
        if( !contract || !pool || amount.length <= 0){
          setStat({value: 0});
          return;
        }
        // HOW MUCH APPROVED
        async function getStakeInfo() {
          try {
            const res = await contract?.estimatePreSale(ethers.utils.parseUnits(amount.toString(), 18));
            setStat({value: res});
          } catch (err) {
            console.error(err);
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, amount, pool, contract]);
      return stat;
}

export const useTokenPrice = (chainId: number, lastPairUpdates: number) => {
    const { account, connector } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const nativePair = "0x9c0Dd6BA0E2c611585c75F06f024BC8826FdB446";
    const usdcPair = "0x29715d8D279cAB143A12fF515b40a2b35d7BAD37";

    useEffect(() => {
        // if( !contract ){
        //   setStat({value: 0});
        //   return;
        // }
        // HOW MUCH APPROVED
        async function getStakeInfo() {
          try {


            if((Date.now().valueOf() - lastPairUpdates) <= 30000)
            {
                return;
            }
                
            // const token0Native = await contract.token0();
            // const token1Native = await contract.token1();
            
            // const provider = new providers.Web3Provider(connector.provider!);
            
            // const token0Contract = getContract(token0Native, ERC20, provider, account ? account : undefined);
            // const token1Contract = getContract(token1Native, ERC20, provider, account ? account : undefined);

            // const token0NativeDecimals = await token0Contract.decimals();
            // const token1NativeDecimals = await token1Contract.decimals();


            // const token0NativeBalance = await token0Contract.balanceOf(nativePair);
            // const token1NativeBalance = await token1Contract.balanceOf(nativePair);
            
            // let priceLp = 1;

            // const format0 = ethers.utils.formatUnits(BigNumber.from(token0NativeBalance), token0NativeDecimals);
            // const format1 = ethers.utils.formatUnits(token1NativeBalance, token1NativeDecimals);
            // priceLp = Number(format1) / Number(format0)
            const price = await getTokenPriceFromCGC("fantom");
            if( price ){
                if(price.pairs.length > 0){
                    setStat({value: price.pairs[0].priceUsd});
                }
            }
            // setStat({value: res});
          } catch (err) {
            console.log(err);
          }
        }
     
        getStakeInfo().then();
      }, [ chainId]);
      return stat;
}

export const useStake = (chainId: number, amount: string, info: any, poolInfo: any) => {
    const { account } = useWeb3React();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, info ? info?.masterChefAddress : '');
    const dispatch = useDispatch();
    const { onShowNotification } = useNotification();
    const tokenContract = useToken( info?.isLp ? info?.stakingToken0 : info?.stakingToken);

    const handleStake= useCallback(
        async (estimate: string): Promise<string | undefined> => {
    
          if (!account || !contract || !info ) return '';

          const tx = await contract.stake(info.poolId, ethers.utils.parseUnits(amount,18)); 
    
        //   onShowNotification({
        //     title: 'Transaction Pending',
        //     description: 'Bridge Token',
        //     hasView: true,
        //     txHash: tx.hash,
        //   });
      
        //   dispatch(setPendingTxHash(tx.hash));

          const receipt = await tx.wait();
    
          if (receipt.status !== 1) {
            throw new Error();
          }
          dispatch(fetchDeployedTokensDataAsync(account ? account : ""));

        //   dispatch(fetchBridgeUserDataAsync(account, chainId.toFixed(), info, tokenFrom));
          // dispatch(fetchPresaleUserInfoAsync(chainId.toString(), account))
          return tx.txHash;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [account, contract, amount]
    );

    const handleUnStake= useCallback(
      async (estimate: string): Promise<string | undefined> => {
  
        if (!account || !contract || !info ) return '';
        
        if( estimate === "0" ) {
            const tx  = await contract.unstake(info.poolId, 0); 
            const receipt = await tx.wait();
  
            if (receipt.status !== 1) {
              throw new Error();
            }
          //   dispatch(fetchBridgeUserDataAsync(account, chainId.toFixed(), info, tokenFrom));
          dispatch(fetchDeployedTokensDataAsync(account ? account : ""));
            return tx.txHash;
        }
        else {
           const tx = await contract.unstake(info.poolId, ethers.utils.parseUnits(amount,18)); 
           const receipt = await tx.wait();
  
           if (receipt.status !== 1) {
             throw new Error();
           }
         //   dispatch(fetchBridgeUserDataAsync(account, chainId.toFixed(), info, tokenFrom));
           dispatch(fetchPresaleUserInfoAsync(chainId.toString(), account))
           return tx.txHash;

        }
      //   onShowNotification({
      //     title: 'Transaction Pending',
      //     description: 'Bridge Token',
      //     hasView: true,
      //     txHash: tx.hash,
      //   });
    
      //   dispatch(setPendingTxHash(tx.hash));


      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contract, amount]
  );

    const handleApprovePurchaseToken = useCallback(async (): Promise<string | undefined> => {
        if (!account || !contract || !tokenContract ) return '';
        const tx = await tokenContract.approve(contract.address, ethers.constants.MaxUint256);
    
        dispatch(setPendingTxHash(tx.hash));
        const receipt = await tx.wait();
    
        if (receipt.status !== 1) {
          throw new Error();
        }
    
        // dispatch(fetchTheatreUserDataAsync(account, selectedChainId));
    
        return tx.txHash;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [account, dispatch, contract, tokenContract]);

    return {
        onStake: handleStake,
        onApprove: handleApprovePurchaseToken,
        onUnstake: handleUnStake
    }
}

import { FC } from 'react';
import { Box, styled } from '@mui/material';

import StakingPoolCard from 'components/Earn/StakingLp/StakingPoolCard';
import { StakeCategory } from 'types/stake';
// import useTokenPrice from 'hooks/useTokenPrice';
import { useAppSelector } from 'state/hooks';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: 'calc(100vh - 194px)',

  [theme.breakpoints.up('xs')]: {
    minHeight: 'calc(100vh - 284px)',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: 'calc(100vh - 194px)',
  },
}));

const PoolListBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginTop: '10px',
}));

const StakingsLp: FC = () => {
  // const { tokenPrices } = useTokenPrice();
  // const { data: tokenList } = useAppSelector((state) => state.tokens);
  // const { data: stakingPoolList } = useAppSelector((state) => state.stakingPools);
  const { data: farmList } = useAppSelector((state) => state.stake);

  // const getTokenPrice = (tokenAddr: string) => {
  //   const tokenInfo = tokenList.find((row) => row.address.toLowerCase() === tokenAddr.toLowerCase());
  //   if (tokenInfo) return tokenInfo.priceInUsd || 0;
  //   return 0;
  // };

  const getStakingPoolAprAndTvl = (pool: any) => {
    // const { stakingToken, rewardToken, rewardEmissionRate, tvl, type: poolType, infinitePrinterApr } = pool;

    // const stakingTokenPrice = getTokenPrice(stakingToken);
    // const rewardTokenPrice = getTokenPrice(rewardToken);
    // const tvlInUsd = stakingTokenPrice * tvl; // calculate tvl in usd

    // if (poolType === 'Acropolis') {
    //   // calculate apr
    //   const YEAR_IN_DAYS = 365;
    //   const rewardPerDay = 4 * rewardTokenPrice * rewardEmissionRate;
    //   const rewardPerYear = YEAR_IN_DAYS * rewardPerDay;
    //   const apr = (rewardPerYear / tvlInUsd) * 100;

    //   return { apr, tvlInUsd };
    // }

    // if (poolType === 'Boardroom') {
    //   // calculate apr
    //   const rewardTokenCirculatingSupply = pool?.rewardTokenCirculatingSupply || 0;

    //   const YEAR_IN_DAYS = 365;
    //   const rewardPerDay = 80 * rewardTokenPrice * rewardTokenCirculatingSupply * infinitePrinterApr;

    //   const rewardPerYear = YEAR_IN_DAYS * rewardPerDay;
    //   const apr = rewardPerYear / tvlInUsd;

    //   return { apr, tvlInUsd };
    // }

    // return { apr: 0, tvlInUsd };
  };

  const getFarmPoolAprAndTvl = (farm: any) => {
    // let apr = 0;
    // let tvlInUsd = 0;

    // if (farm.category === FarmCategory.ACTIVE || farm.category === FarmCategory.ACTIVE_RETIRED) {
    //   const {
    //     rewardPerSecond,
    //     poolWeight,
    //     rewardToken,
    //     stakingToken,
    //     stakingToken0,
    //     stakingToken1,
    //     token1Amount,
    //     tvl,
    //   } = farm;
    //   const YEAR_IN_SECONDS = 3600 * 24 * 265;
    //   const rewardTokenPrice = getTokenPrice(rewardToken);
    //   const rewardPerYear = poolWeight * rewardTokenPrice * rewardPerSecond * YEAR_IN_SECONDS;

    //   if (stakingToken0 === '0x0000000000000000000000000000000000000000') {
    //     tvlInUsd = getTokenPrice(stakingToken) * tvl;
    //   } else {
    //     tvlInUsd = 2 * getTokenPrice(stakingToken1) * token1Amount;
    //   }
      
    //   apr = (rewardPerYear / tvlInUsd) * 100;
    //   if( farm?.isAprZero )
    //     apr = 0;
    //   return { apr, tvlInUsd };
    // }

    // if (farm.category === FarmCategory.RETIRED) {
    //   const { stakingToken1, token1Amount } = farm;
    //   const token1Price = getTokenPrice(stakingToken1);
    //   tvlInUsd = 2 * token1Price * token1Amount;

    //   return { apr, tvlInUsd };
    // }

    // return {
    //   apr,
    //   tvlInUsd,
    // };
  };

  const farmPools = farmList
    .filter((row) => row?.location === 'stakingLp')
    .map((row1) => {
      // const { apr, tvlInUsd } = getFarmPoolAprAndTvl(row1);
      return {
        ...row1,
        // apr,
        // dailyApr: apr / 365,
        // tvlInUsd,
        apr: 0,
        dailyApr: 0,
        tvlInUsd: 0
      };
    });

  return (
    <Container>
      <PoolListBody>
        {/* farming pools */}
        <>{farmPools && farmPools.map((row: any) => <StakingPoolCard key={row.name} pool={row} />)}</>
      </PoolListBody>
    </Container>
  );
};

export default StakingsLp;

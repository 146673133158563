import { FC, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { BigNumber } from 'ethers';
import moment from 'moment';

import { useAppSelector } from 'state/hooks';
import { icons } from 'config/constants/assets';
import { ContainedButton } from 'components/common/Button';
// import { StakingModal } from 'components/Modal';
import { getBalanceInEther, getBalanceInWei, toTwoDigits } from 'utils';
// import { useStakingPool } from 'hooks/useStakingPool';
// import {useClaimRewardTimerBoardroom, useWithdrawTimerStaking} from 'hooks/useStakingPoolLockInfo';
import { useNotification } from 'hooks/useNotification';
import { ContainedInput } from 'components/common/Input';
import TabPanel from './TabPanel';
import { useStakeEstimateInfo, useTokenPrice } from 'hooks/useStakeInfo';
import CountDown from './CountDown';
import { useWeb3React } from '@web3-react/core';
import CountDownStart from './CountDownStart';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
//   borderRadius: '20px',
  background: "#2A3239",
  boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',

  [theme.breakpoints.up('xs')]: {
    padding: '0px 16px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '0px 32px',
  },
}));

// origin section
const Origin = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  textAlign: 'center',
  gap: '0px 15px',
  padding: '16px 0px',
  [theme.breakpoints.up('xs')]: {
    gap: '15px 15px',
    justifyContent: 'center',

  },
  [theme.breakpoints.up('md')]: {
    gap: '15px 15px',
    justifyContent: 'space-between',
  },
}));

// token img section
const TokenImgSection = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const TokenImg = styled('img')(({ theme }) => ({
  width: '60px',

  [theme.breakpoints.up('xs')]: {
    width: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '60px',
  },
}));

const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'center',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    textAlign: 'start',
    whiteSpace: 'break-spaces',
  },
}));

// info section
const InfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    gap: '15px',
    width: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    gap: '30px',
  },
}));

const InfoCell = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'rgba(255, 255, 255, 0.7)',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '30px',
  color: '#ffffff',
  textAlign: 'start',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'end',
  margin: 'auto',

  span: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    marginLeft: '4px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '13px',
    lineHeight: '23px',
    span: {
      fontSize: '8px',
      lineHeight: '14px',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '24px',
    span: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

const RewardImg = styled('img')(() => ({
  width: '22px',
}));

// desktop expanded section
const DesktopExpanded = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  gap: '18px',
  alignItems: 'center',
  padding: '0px 0px 16px',

  [theme.breakpoints.up('xs')]: {
    border: 'none',
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {},
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// global info section
const GlobalInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0px 15px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  borderBottom: '1.5px solid rgba(255, 255, 255, 0.3)',
  flexWrap: 'wrap',

  [theme.breakpoints.up('xs')]: {
    borderTop: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  },
  [theme.breakpoints.up('md')]: {},
}));

const GlobalInfoCard = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',

  [theme.breakpoints.up('xs')]: {
    padding: '10px 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15px 0px',
  },
}));

const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',

  color: 'rgba(255, 255, 255, 0.7)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'block',
    },
  },
}));

const GlobalInfoCardLabel2 = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',

  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  [theme.breakpoints.up('xs')]: {
    fontSize: '15px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
    lineHeight: '18px',
    img: {
      display: 'block',
    },
  },
}));

const GlobalInfoCardValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  color: '#ffffff',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
}));

// action section
const ActionSection = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
}));

const ActionInfoSection = styled(Box)(() => ({
  maxWidth: '130px',
  margin: 'auto',
  marginBottom: '6px',
  minWidth: '130px'
}));

const ActionInfo1 = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  whiteSpace: 'break-spaces',
  color: 'rgba(255, 255, 255, 0.7)',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}));

const ActionInfo2 = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  whiteSpace: 'break-spaces',
  color: 'rgba(255, 255, 255, 255)',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
}));

const ButtonSection = styled(Box)(() => ({}));

const ActionButton = styled(ContainedButton)(() => ({
  maxWidth: '102px',
  height: '40px',
}));

// mobile expanded section
const MobileExpanded = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  gap: '18px',
  alignItems: 'center',
  padding: '0px 0px 12px',

  [theme.breakpoints.up('xs')]: {
    border: 'none',
    display: 'flex',
    borderTop: '2px solid #CCC292',
  },
  [theme.breakpoints.up('sm')]: {
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

// global info section
const MobileGlobalInfoCard = styled(Box)(() => ({
  textAlign: 'center',
}));

const MobileGlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  color: 'rgba(255, 255, 255, 0.7)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  lineHeight: 'normal',
  maxWidth: '150px',
  whiteSpace: 'break-spaces',

  [theme.breakpoints.up('xs')]: {},
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const MobileGlobalInfoCardValue = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  color: '#ffffff',
  fontSize: '12px',
  lineHeight: 'normal',
}));

const AmountInput = styled(ContainedInput)(() => ({
  height: '27px',
  maxWidth: '100px',
  marginRight: '5px',
  marginLeft: '5px'
}));

// action section
const MobileButtonSection = styled(Box)(() => ({
  textAlign: 'center',
}));

const MobileActionButton = styled(ContainedButton)(() => ({
  maxWidth: '102px',
  height: '40px',
}));

interface PoolCardProps {
  pool: any;
}

const StakingPoolCard: FC<PoolCardProps> = ({ pool }) => {
  const [isExpanded, setExpanded] = useState<boolean>(!!true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string | undefined>();
  const [isPending, setPending] = useState<boolean>(false);
  const [nextEpochCountDown, setNextEpochCountDown] = useState<string>('');
  const [claimTimeout, setClaimTimeout] = useState('');
  const [unstakeTimeout, setUnstakeTimeout] = useState('');
  const { account } = useWeb3React();

//   const { onClaim, onKickPrinter } = useStakingPool(pool);
//   const { data: tokenList } = useAppSelector((state) => state.tokens);
  const { onShowNotification } = useNotification();
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const [amount, setAmount] = useState<string>('');
  const [amountInWei, setAmountInWei] = useState<BigNumber>(BigNumber.from(0));
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const [lastPriceUpdate, setLastPriceUpdate] = useState<number>(0);
  const price = useTokenPrice(Number(selectedChainId), lastPriceUpdate)

  const {
    stakingToken,
    rewardToken,
    stakingTokenName,
    rewardTokenName,
    userInfo,
    nextEpochStartTimestamp,
    rewardPerShare,
    currentEpoch,
    period,
    name
  } = pool;
  const rewardBalance = Number(
    Number(userInfo?.rewardTokenBalance || BigNumber.from(0)) / 10 ** (userInfo?.rewardTokenDecimal || 18)
  );
  const stakedBalance = getBalanceInEther(userInfo?.stakedBalance || BigNumber.from(0));
  const canWithdraw = !!userInfo?.canWithdraw && stakedBalance > 0;
  const canClaimReward = !!userInfo?.canClaimReward;

  const [stakedDFund, setStakedDfund] = useState<number>(0);
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [tvl, setTvl] = useState<number>(0);
  const [apr, setApr] = useState<number>(0);
  const [dailyApr, setDailyApr] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [pendingRewards, setPendingRewards] = useState<number>(0);
  const [stakeTime, setStakeTime] = useState<number>(0);
  const [lockTime, setLockTime] = useState<number>(0)
  const [counter, setCounter] = useState<number>(0)

  const isClaimable = Math.floor((new Date()).getTime() / 1000) > lockTime

  const info = useStakeEstimateInfo(Number(selectedChainId), pool, counter)
  useEffect(() => {
    setLastPriceUpdate(Date.now().valueOf())
}, [price]);

useEffect(() => {
  if( info  ){
    setApr(info.aprYear)
    setDailyApr(info.aprYear/ 365)
    let pp = 0;
    if( price !== undefined )
      pp = price.value
    setTvl(info.tvl*pp)
    setStakedDfund(getBalanceInEther(info.userStaked))
    setPendingRewards(getBalanceInEther(info.pendingRewards))

    if(Number(getBalanceInEther(info.pendingRewards)) < 0 )
      setPendingRewards(0)

    setLockTime(Number(info.stakeTime) + Number(info.lockTime))
  }
}, [info, price, account]);;

  const onToggle = () => {
    setExpanded(!isExpanded);
  };

  const onDeposit = () => {
    setModalType('DEPOSIT');
    setModalOpen(true);
  };

  const onWithdraw = () => {
    setModalType('WITHDRAW');
    setModalOpen(true);
  };

  function isNumeric(n: any) {
    return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
  }

  const onChangeAmount = (e: any) => {
    if (!isNumeric(e.target.value)) return;
    const newAmount = e.target.value;
    if( Number(newAmount) > 2 )
      return
    setAmount(newAmount);
    setAmountInWei(getBalanceInWei(Number(newAmount).toString() || '0'));
  };

  const onClaimReward = async () => {
    setPending(true);
  };
  const onCloseModal = () => {
    setModalOpen(false);
    setModalType(undefined);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        style={{width: '100%'}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} sx={{width: '100%'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }
// https://dex.swapdegen.tips/#/add/v2/EVMOS/0x0B946D939bb93609Fcce42220180E5C81B642786
  const onUpdate = () => {
      setCounter(counter+1);
  };

  const onBuyLp = () => {
    window.open("https://dex.swapdegen.tips/#/add/v2/EVMOS/0x0B946D939bb93609Fcce42220180E5C81B642786")
};
//   Balance in Wallet
// Your Staked DFund
// DFund rewards accumulated
// Time until no tax claim
// Early withdraw tax

  // expanded view for desktop
  const renderDesktopExpandedView = () => (
    <DesktopExpanded>
      <GlobalInfoSection>
        {/* total token Staked */}
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`Your Staked ${pool?.isLp ? 'Lps' : 'DFund' }`}</GlobalInfoCardLabel>
          <GlobalInfoCardValue>{`${Number(Number(stakedDFund || 0).toFixed(0)).toLocaleString()}`}</GlobalInfoCardValue>
        </GlobalInfoCard>
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`DFund rewards accumulated`}</GlobalInfoCardLabel>
          <GlobalInfoCardValue>{`${Number(Number(pendingRewards).toFixed(4))}`}</GlobalInfoCardValue>
        </GlobalInfoCard>
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`Time until no tax claim`}</GlobalInfoCardLabel>
          <CountDown lockTime={lockTime}/>
        </GlobalInfoCard>
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`Early withdraw tax`}</GlobalInfoCardLabel>
          <GlobalInfoCardValue>{`10%`}</GlobalInfoCardValue>
        </GlobalInfoCard>
      </GlobalInfoSection>
      <ActionSection>
      <ActionButton variant='outlined'  disabled={false} onClick={onBuyLp} sx={{ maxWidth: '152px', marginLeft: '5px' }}>
                            {"Add LP"}
      </ActionButton>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} sx={{width: '100%'}} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Stake" {...a11yProps(0)} />
          <Tab label="Unstake" {...a11yProps(1)} />
          <Tab label="Claim" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box display='flex' sx={{width: '100%'}}>
      <CustomTabPanel value={value} index={0}>
        <TabPanel key={1} info={info} type='Stake' onUpdate={onUpdate} pool={pool} isClaimable={isClaimable}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TabPanel key={2} info={info} type='Unstake' onUpdate={onUpdate}  pool={pool} isClaimable={isClaimable}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TabPanel key={3} info={info} type='Claim' onUpdate={onUpdate}  pool={pool} isClaimable={isClaimable}/>
      </CustomTabPanel>
      </Box>

      </ActionSection>
    </DesktopExpanded>
  );

  // expanded view for mobile
  const renderMobileExpandedView = () => (
    <MobileExpanded>
        <GlobalInfoSection>
        {/* total token Staked */}
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`Your Staked DFund`}</GlobalInfoCardLabel>
          <GlobalInfoCardValue>{`${Number(Number(stakedDFund || 0).toFixed(0)).toLocaleString()}`}</GlobalInfoCardValue>
        </GlobalInfoCard>
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`DFund rewards accumulated`}</GlobalInfoCardLabel>
          <GlobalInfoCardValue>{`${Number(Number(pendingRewards|| 0).toFixed(4))}`}</GlobalInfoCardValue>
        </GlobalInfoCard>
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`Time until no tax claim`}</GlobalInfoCardLabel>
          <CountDown lockTime={lockTime}/>
        </GlobalInfoCard>
        <GlobalInfoCard>
          <GlobalInfoCardLabel>{`Early withdraw tax`}</GlobalInfoCardLabel>
          <GlobalInfoCardValue>{`10%`}</GlobalInfoCardValue>
        </GlobalInfoCard>
      </GlobalInfoSection>
      <ActionButton variant='outlined'  disabled={false} onClick={onBuyLp} sx={{ maxWidth: '152px', marginLeft: '5px' }}>
                            {"Add LP"}
                    </ActionButton>
      <ActionSection>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} sx={{width: '100%'}} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Stake" {...a11yProps(0)} />
          <Tab label="Unstake" {...a11yProps(1)} />
          <Tab label="Claim" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box display='flex' sx={{width: '100%'}}>
      <CustomTabPanel value={value} index={0}>
        <TabPanel key={1} type='Stake'  onUpdate={onUpdate} pool={pool} info={info} isClaimable={isClaimable}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TabPanel key={2} type='Unstake' onUpdate={onUpdate} pool={pool} info={info} isClaimable={isClaimable}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TabPanel key={3} type='Claim'  onUpdate={onUpdate} pool={pool} info={info} isClaimable={isClaimable}/>
      </CustomTabPanel>
      </Box>
      </ActionSection>
    </MobileExpanded>
  );

  return (
    <>
      {/* {modalOpen && modalType && <StakingModal info={pool} modalType={modalType} onClose={onCloseModal} />} */}

      <Container>
        <Origin>
          <TokenImgSection>
            <PoolName>{pool.name}</PoolName>
          </TokenImgSection>
          <InfoSection>
            <InfoCell>
              <InfoLabel>
                {/* <span>
                  <img alt="price" src={icons.dollar} />
                </span> */}
                APR
              </InfoLabel>
              <InfoValue>{`${(apr || 0).toFixed(2)}%`}</InfoValue>
            </InfoCell>
            <InfoCell>
              <InfoLabel>
                {/* <span>
                  <img alt="price" src={icons.dollar} />
                </span> */}
                Daily APR
              </InfoLabel>
              <InfoValue>{`${(dailyApr || 0).toFixed(2)}%`}</InfoValue>
            </InfoCell>
            <InfoCell>
              <InfoLabel>
                {/* <span>
                  <img alt="price" src={icons.circleUp} />
                </span> */}
                TVL
              </InfoLabel>
              <InfoValue>{` $${Number((tvl || 0).toFixed(2))}`}</InfoValue>
            </InfoCell>
          </InfoSection>
        </Origin>
        {renderDesktopExpandedView()}
        {renderMobileExpandedView()}
      </Container>
    </>
  );
};

export default StakingPoolCard;

import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, providers } from 'ethers';
import { useToken } from './useContract';

export const useDFundBalance = () => {
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0));

  const { account, chainId, connector } = useWeb3React();
  
  const tokenContract = useToken("0x0B946D939bb93609Fcce42220180E5C81B642786");

  const fetchBalance = useCallback(async () => {
    if (!account) return;
    if (!chainId) return;
    if (!tokenContract) return;
    try{
      const provider = new providers.Web3Provider(connector.provider!);
      const balance = await tokenContract.balanceOf(account);
    //   const rawBalance = await provider.getBalance(account);
       setBalance(balance);
    }
    catch(err){
      console.log(err)
    }
  }, [account, chainId, connector, tokenContract]);

  useEffect(() => {
    fetchBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);

  useEffect(() => {
    fetchBalance();

    setTimeout(() => {
      fetchBalance();
    }, 30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return balance;
};

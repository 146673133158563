/* eslint-disable no-console */
import axios from 'axios';

export const getTokenIdFromCGC = (nativeCurrency: string): string => {
  if (nativeCurrency === 'FTM') return 'fantom';

  return 'fantom';
};

export const getTokenPriceFromCGC = async (tokenId: string): Promise<any> => {
  if (!tokenId) return 0;
  const cgcUrl = `https://api.dexscreener.com/latest/dex/pairs/degenchain/0x9c0Dd6BA0E2c611585c75F06f024BC8826FdB446`;
  try {
    const res = await axios.get(cgcUrl, {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('Coingecko token price fetch error: ', err);
    return 0;
  }

  return 0;
};

export const getFTMPriceFromCGC = async (): Promise<number> => getTokenPriceFromCGC(getTokenIdFromCGC('FTM'));

import { FC, useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';

import { icons, images } from 'config/constants/assets';
import Stakings from 'components/Earn/Stakings'
import { useAppSelector } from 'state/hooks';
import StakingsLp from 'components/Earn/StakingLp';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: 'calc(100vh - 194px)',
  padding: '0px 24px',
  paddingTop: '19px',

  [theme.breakpoints.up('xs')]: {
    minHeight: 'calc(100vh - 284px)',
    paddingTop: '12px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: 'calc(100vh - 194px)',
    paddingTop: '19px',
  },
}));


const Content = styled(Box)(() => ({
  position: 'relative',
  maxWidth: '851px',
  margin: 'auto',
}));

// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  maxWidth: '100%',
  overflowX: 'auto',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    gap: '10px',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '20px',
  },
}));

// news box
const NewsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  background: 'rgba(42, 49, 57, 0.4)',
  boxShadow:
      '0px 100px 77px rgba(0, 0, 0, 0.0), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0)',
  borderRadius: '20px',
  padding: '20px 10px',

  [theme.breakpoints.up('xs')]: {
    padding: '0px',
    boxShadow: 'none',
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px 10px',
    boxShadow:
      '0px 100px 77px rgba(0, 0, 0, 0.0), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0)',
  },
}));

const NewsContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: '#CCC292',
  borderRadius: '20px',
  padding: '15px',
  height: '160px',
  overflow: 'hidden',
}));

const NewsTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  color: 'rgba(39, 40, 42, 0.8)',
  fontSize: '16px',
  lineHeight: '18px',
  textAlign: 'center',
  zIndex: '2',
}));

const NewsTitle2 = styled(Typography)(({ theme }) => ({
  position: 'relative',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  color: 'rgba(39, 40, 42, 0.8)',
  fontSize: '20px',
  lineHeight: '18px',
  textAlign: 'center',
  zIndex: '2',
}));

const NewsSubTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  color: 'rgba(39, 40, 42, 0.6)',
  fontSize: '11px',
  lineHeight: 'normal',
  zIndex: '2',
}));

const NewsImg = styled('img')(({ theme }) => ({
  marginTop: '18px',

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 0px)',
    height: '80px',
    marginTop: '28px',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '18px',
  },
}));

const StampImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: '3px',
  left: '16px',
  width: '210px',

  [theme.breakpoints.up('xs')]: {},
  [theme.breakpoints.up('md')]: {},
}));

// body section
const BodySection = styled(Box)(({ theme }) => ({
  marginTop: '10px',
}));

const ContentLeft = styled(Box)(({ theme }) => ({
  width: '218px',
  minWidth: '218px',
  padding: '0px 24px',
  marginTop: '52px',

  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentRight = styled(Box)(() => ({
  width: '100%',
}));

const earnOptions = [
  { value: 'farms', label: 'Farms' },
  { value: 'staking', label: 'Staking Pools' },
  { value: 'nftStaking', label: 'NFT Yield' },
  { value: 'dashboard', label: 'My Dashboard' },
];

const earnOptionsOther = [
  { value: 'nftStaking', label: 'NFT Yield' },
  { value: 'dashboard', label: 'My Dashboard' },
];

const StakeLpPage: FC = () => {
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const [activeOptions, setActiveOptions] = useState<any>(earnOptions);
  const [activeEarnOption, setActiveEarnOption] = useState<any>(earnOptions[0]);

  const onSelectEarnOption = (earnOption: any) => {
    if (earnOption.value === activeEarnOption.value) return;
    setActiveEarnOption(earnOption);
  };

  useEffect(() => {
    if( Number(selectedChainId) !== 250 ){
      setActiveOptions(earnOptionsOther);
      setActiveEarnOption(earnOptionsOther[0])
    }
    else{
      setActiveOptions(earnOptions);
      setActiveEarnOption(earnOptions[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId]);

  return (
    <Container>
      <Content>
        {/* overview section */}
        {/* pool list section */}
        <BodySection>
          <Box sx={{ display: 'flex', gap: '20px', maxWidth: '100%', marginTop: '10px' }}>
              <StakingsLp />
          </Box>
        </BodySection>
      </Content>
    </Container>
  );
};

export default StakeLpPage;
